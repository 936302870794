import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.pump-image-thumb')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href*="#"] ', {offset: 100})
})

$(document).ready(function () {
    var selector = '.second-level-sub-navigation li';
    $(selector).on('click', function() {
        $(selector).removeClass('current');
        $(this).addClass('current');
    });
  })

  $(document).ready(function() {
    if($('.second-level-sub-navigation').hasClass('visible-xs')) {
        $('.header').addClass('distance');
    }
  });

$(window).scroll(function () {
    var position = window.pageYOffset;
    $('.grid-content').each(function () {
      var target = $(this).offset().top;
      var id = $(this).attr('id');
      var navLinks = $('.second-level-sub-navigation li a');
      var navLinksHome = $('.header-nav-list li a');
      if (position >= target - 220 && id) {
        navLinks.removeClass('active');
        navLinksHome.removeClass('active');
        $('.second-level-sub-navigation li a[href*="#' + id + '"]').addClass('active');
        $('.header-nav-list li a[href*="#' + id + '"]').addClass('active');
        $('.second-level-sub-navigation li').removeClass('current');
      } else {
        $('.second-level-sub-navigation li current').addClass('current');
      }
    });
 });

// close burger menu if cklcik on id to show target
 $('.header-nav-list li a').click(function() {
    $('.header-nav-content').collapse('hide');
});

$(document).ready(function() {
  // Add smooth scrolling to all links
  $('.header li a').on('click', function(event) {
    if (this.hash !== '') {
      var hash = this.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top - $('.header').height() - 40
      }, 500, function() {

        // window.location.hash = hash;
      });
      return false;
    }
  });
});

const triggerTabList = [].slice.call(document.querySelectorAll('#item-tab li:first-child .nav-link'))
triggerTabList.forEach(function (triggerEl) {
  const tabTrigger = new bootstrap.Tab(triggerEl)

  triggerEl.addEventListener('click', function (event) {
    event.preventDefault()
    tabTrigger.show()
  })
})

$('.tab--wrapper .tab-content .tab-pane:first').addClass('show active')
$('.tab--wrapper #item-tab li .nav-link:first').addClass('active')

$('.carousel').carousel({
  interval: false
})

$(window).scroll(function() {
  if ($(this).scrollTop() >= 600) {
      $('.back-to-top').fadeIn(300).addClass('visible');
  } else {
      $('.back-to-top').fadeOut(300).removeClass('visible');
  }
});

$('.back-to-top').click(function() {
  $('body,html').animate({
      scrollTop : 0
  }, 300);
});

$(document).ready(function () {
  $('.icon-group').on('click', function () {
    $(this).parent().toggleClass('active')
  })
})

$(document).ready(function () {
  $('.process-plan--item .close').on('click', function () {
    $(this).parent().removeClass('show')
    $('.overlay').removeClass('active')
  })
})

$('.content-group a').click(function () {
  const classClicked = $(this).attr('class')
  $('.neos-contentcollection .process-plan--item').removeClass('show')
  $('.item-' + classClicked).addClass('show')
  $('.item-0').removeClass('show')
  if ($('.neos-contentcollection .process-plan--item').hasClass('show')) {
    $('.overlay').addClass('active')
  } else {
    $('.overlay').removeClass('active')
  }
})
const closePopup = document.getElementById('overlay-wrapper')

closePopup.addEventListener('click', function () {
  $('.overlay').removeClass('active')
  $('.overlay .neos-contentcollection div').removeClass('show')
})