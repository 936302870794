import contact from './lib/contact'
import rebox from './lib/rebox'
import { HistoryTimelineView } from './lib/history-timeline.js'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'
import { Splide } from '@splidejs/splide'

(function () {
  'use strict'

  // NOTE: This way of detecting hover support is less than ideal.
  // Many devices support both touch and hover.
  var supportsTouch = (typeof Touch === 'object')
  var supportsHover = !supportsTouch
  var isSmallDevice = window.matchMedia('(max-width: 992px)').matches;

  (function () {
    // Timeline
    $(document).ready(function () {
      window.historyTimelineView = new HistoryTimelineView({el: '#history-timeline'})
    })
  })()
})()


$(document).ready(function () {
  contact('#contact-form')
  rebox('.pump-image-thumb')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href*="#"] ', {offset: 100})
})

document.addEventListener('DOMContentLoaded', function () {
  const elementInfo = document.querySelector('.job-area-slider')
  const elementIconSlider = document.querySelector('.icon-text-slider')

  if (elementIconSlider) {
    console.log('Element gefunden:', elementIconSlider)
    const splide = new Splide('.splide.icon-text', {
      perPage: 3,
      perMove: 3,
      gap: 20,
      mediaQuery: 'max',
      breakpoints: {
        1024: {
          perPage: 1,
          perMove: 1,
          padding: '2rem',
          gap: 33
        },
        500: {
          padding: '1rem'
        }
      }
    })
    splide.mount()
  } else {
    console.log('Kein Element mit der Klasse "icon-text-slider" gefunden.')
  }

  if (elementInfo) {
    console.log('Element gefunden:', elementInfo)
    const splide = new Splide('.splide.job-area', {
      perPage: 1,
      perMove: 1,
      gap: 35,
      padding: '1rem',
      mediaQuery: 'min',
      breakpoints: {
        1200: {
          destroy: true
        },
        500: {
          perPage: 2,
          perMove: 2,
          padding: '2.2rem',
          gap: 25
        }
      }
    })
    splide.mount()
  } else {
    console.log('Kein Element mit der Klasse "job-area-slider" gefunden.')
  }
})

$(document).ready(function () {
    var selector = '.second-level-sub-navigation li';
    $(selector).on('click', function() {
        $(selector).removeClass('current');
        $(this).addClass('current');
    });
  })

  $(document).ready(function() {
    if($('.second-level-sub-navigation').hasClass('visible-xs')) {
        $('.header').addClass('distance');
    }
  });

$(window).scroll(function () {
    var position = window.pageYOffset;
    $('.grid-content').each(function () {
      var target = $(this).offset().top;
      var id = $(this).attr('id');
      var navLinks = $('.second-level-sub-navigation li a');
      var navLinksHome = $('.header-nav-list li a');
      if (position >= target - 220 && id) {
        navLinks.removeClass('active');
        navLinksHome.removeClass('active');
        $('.second-level-sub-navigation li a[href*="#' + id + '"]').addClass('active');
        $('.header-nav-list li a[href*="#' + id + '"]').addClass('active');
        $('.second-level-sub-navigation li').removeClass('current');
      } else {
        $('.second-level-sub-navigation li current').addClass('current');
      }
    });
 });

// close burger menu if cklcik on id to show target
 $('.header-nav-list li a').click(function() {
    $('.header-nav-content').collapse('hide');
});

$(document).ready(function() {
  // Add smooth scrolling to all links
  $('.header li a').on('click', function(event) {
    if (this.hash !== '') {
      var hash = this.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top - $('.header').height() - 40
      }, 500, function() {

        // window.location.hash = hash;
      });
      return false;
    }
  });
});

const triggerTabList = [].slice.call(document.querySelectorAll('#item-tab li:first-child .nav-link'))
triggerTabList.forEach(function (triggerEl) {
  const tabTrigger = new bootstrap.Tab(triggerEl)

  triggerEl.addEventListener('click', function (event) {
    event.preventDefault()
    tabTrigger.show()
  })
})

$('.tab--wrapper .tab-content .tab-pane:first').addClass('show active')
$('.tab--wrapper #item-tab li .nav-link:first').addClass('active')


function initTabs () {
  // Finde alle Tab-Container auf der Seite
  const tabContainers = document.querySelectorAll('.tab--wrapper')

  // Iteriere durch alle Tab-Container
  tabContainers.forEach((tabContainer) => {
    // Finde die Tab-Links und Tab-Panes innerhalb des aktuellen Tab-Container
    const tabLinks = tabContainer.querySelectorAll('.nav-link')
    const tabContents = tabContainer.querySelectorAll('.tab-pane')

    // Füge einen Klick-Event-Listener zu allen Tab-Links in diesem Tab-Container hinzu
    tabLinks.forEach((tabLink, index) => {
      tabLink.addEventListener('click', function (e) {
        e.preventDefault()

        tabLinks.forEach((link) => {
          link.classList.remove('active')
        })

        this.classList.add('active')

        tabContents.forEach((content) => {
          content.classList.remove('show', 'active')
        })

        tabContents[index].classList.add('show', 'active')
      })
    })

    const firstTabLink = tabLinks[0]
    if (firstTabLink) {
      firstTabLink.click()
    }
  })
}
initTabs()

// Funktion zum ändern der Tab-Items nach URL Parameter

$(document).ready(function () {
  function processHashChange () {
    let tabContainerCounter = 1

    function generateUniqueId () {
      return tabContainerCounter++
    }

    const hash = window.location.hash
    const hashParts = hash.substring(1).split('-')
    const containerId = hashParts[0]
    const tabPaneId = hashParts.slice(1).join('-')

    $('.tab--wrapper').each(function () {
      const uniqueId = generateUniqueId()
      $(this).addClass('tabContainer' + uniqueId)
      $(this).attr('id', 'tabContainer' + uniqueId)
    })
    if (containerId) {
      $('.' + containerId).each(function () {
        $('.' + containerId + ' .tab-pane').removeClass('show active')
        $('.' + containerId + ' #' + tabPaneId).addClass('show active')
        $('.' + containerId + ' .nav-link').removeClass('active')
        $('.' + containerId + ' .nav-link[href="#' + tabPaneId + '"]').addClass('active')
      })

      // Prüfen, ob sich das Container-Element bereits oben auf der Seite befindet
      if ($(window).scrollTop() !== $('.' + containerId).offset().top - 200) {
        const containerOffset = $('.' + containerId).offset().top - 200
        $('html, body').animate({
          scrollTop: containerOffset
        }, 500)
      }
    }
  }

  // Initialer Aufruf der Funktion zur Verarbeitung des Hash-Werts
  processHashChange()

  // Event-Listener für Änderungen des Hash-Werts der URL
  $(window).on('hashchange', function () {
    processHashChange()
  })
})

$('.carousel').carousel({
  interval: false
})

$(window).scroll(function() {
  if ($(this).scrollTop() >= 600) {
      $('.back-to-top').fadeIn(300).addClass('visible');
  } else {
      $('.back-to-top').fadeOut(300).removeClass('visible');
  }
});

$('.back-to-top').click(function() {
  $('body,html').animate({
      scrollTop : 0
  }, 300);
});

$(document).ready(function () {
  $('.icon-group').on('click', function () {
    $(this).parent().toggleClass('active')
  })
})

$(document).ready(function () {
  $('.process-plan--item .close').on('click', function () {
    $(this).parent().removeClass('show')
    $('.overlay').removeClass('active')
  })
})
$('.content-group a').click(function () {
  const classClicked = $(this).attr('class')
  $('.neos-contentcollection .process-plan--item').removeClass('show')
  $('.item-' + classClicked).addClass('show')
  $('.item-0').removeClass('show')
  if ($('.neos-contentcollection .process-plan--item').hasClass('show')) {
    $('.overlay').addClass('active')
  } else {
    $('.overlay').removeClass('active')
  }
})

const closePopup = document.getElementById('overlay-wrapper')
closePopup.addEventListener('click', function () {
  $('.overlay').removeClass('active')
  $('.overlay .neos-contentcollection div').removeClass('show')
})
